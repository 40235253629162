/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect } from "react";
import tw, { css, theme } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import { useKeyPress } from "~utils/hooks";

const Tip = ({ active, description, image, imageXS, setTipActive, title }) => {
  const { screen } = useContext(DocumentContext);

  let hasTable = false;
  let imageWidth = `100%`;

  //

  switch (title?.toLowerCase()) {
    case `table`:
      imageWidth = `33.333%`;
      break;

    case `color`:
    case `cut`:
      imageWidth = `50%`;
      break;

    case `clarity`:
    case `depth`:
    case `fluorescence`:
    case `l/w ratio`:
      imageWidth = `66.667%`;
      break;

    case `symmetry`:
      imageWidth = `83.333%`;
      break;

    case `supplier standards`:
      hasTable = true;
      imageWidth = `50%`;
      break;

    default:
      break;
  }

  //

  const escKeyPressed = useKeyPress(`Escape`);

  useEffect(() => {
    if (escKeyPressed) {
      setTipActive(false);
    }
  }, [escKeyPressed]);

  //

  return (
    <div
      css={[
        css`
          transition: opacity 0.5s ${A.EASING_CUBIC} 0.1s,
            transform 0.5s ${A.EASING_CUBIC} 0.1s;

          opacity: ${active ? `1` : `0`};
          pointer-events: ${active ? `auto` : `none`};
          transform: scale(${active ? `1` : `1.05`});
        `,
        tw`w-screen h-screen fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-80`
      ]}
    >
      <div tw="w-full relative">
        <Grid>
          <article
            css={[
              css`
                min-height: 25vw;
              `,
              tw`col-span-22 md:col-span-14 col-start-2 md:col-start-6 relative flex flex-col items-center justify-center p-6 md:p-5 bg-white`
            ]}
          >
            <div css={[tw`w-12 h-12 absolute top-0 right-0 z-20`]}>
              <button
                type="button"
                css={[
                  css`
                    pointer-events: ${active ? `auto` : `none`};
                  `,
                  tw`w-12 h-12 relative block`
                ]}
                onClick={() => setTipActive(false)}
              >
                <div tw="w-12 h-12 relative flex items-center justify-center p-2">
                  <Icon.Cross
                    color={theme`colors.koamaru`}
                    styles={[tw`w-12 h-12 relative block`]}
                  />
                </div>
              </button>
            </div>

            <div
              css={[
                css`
                  transition: opacity 0.5s ${A.EASING_CUBIC} 0.25s,
                    transform 0.5s ${A.EASING_CUBIC} 0.25s;

                  transform: translate3d(0, ${active ? `0` : `-6px`}, 0);
                  opacity: ${active ? `1` : `0`};

                  align-items: ${!hasTable && image ? `start` : `center`};
                  justify-content: ${!hasTable && image ? `start` : `center`};
                  margin-top: ${hasTable ? `2rem` : ``};
                  ${screen === `xs` ? `text-align: center;` : ``}
                `,
                tw`w-full relative flex`
              ]}
            >
              <T.Heading
                font={!hasTable && image ? `3` : `2`}
                level="3"
                serif
                styles={[tw`text-koamaru uppercase`]}
              >
                {title || ``}
              </T.Heading>
            </div>

            <div css={[tw`w-full relative flex flex-col items-center`]}>
              {active && (image || imageXS) && (
                <>
                  {(screen === `xs` && (
                    <img
                      css={[
                        css`
                          transition: opacity 0.5s ${A.EASING_CUBIC}
                              ${active ? `0.25s` : `0.1s`},
                            transform 0.5s ${A.EASING_CUBIC}
                              ${active ? `0.25s` : `0.1s`};

                          transform: translate3d(
                            0,
                            ${active ? `0` : `12px`},
                            0
                          );
                          opacity: ${active ? `1` : `0`};

                          width: calc(100% - 3rem);
                          object-fit: contain;

                          margin-top: ${hasTable ? `0.75rem` : `0`};
                          margin-bottom: ${hasTable ? `1.5rem` : `0`};
                          order: ${hasTable ? `1` : `0`};
                        `
                      ]}
                      src={imageXS}
                      alt={`Tip ${title}`}
                    />
                  )) || (
                    <img
                      css={[
                        css`
                          transition: opacity 0.5s ${A.EASING_CUBIC}
                              ${active ? `0.25s` : `0.1s`},
                            transform 0.5s ${A.EASING_CUBIC}
                              ${active ? `0.25s` : `0.1s`};

                          transform: translate3d(
                            0,
                            ${active ? `0` : `12px`},
                            0
                          );
                          opacity: ${active ? `1` : `0`};

                          width: ${imageWidth};
                          max-height: 60vh;
                          object-fit: contain;

                          margin-top: ${hasTable ? `1rem` : `0`};
                          margin-bottom: ${hasTable ? `2rem` : `0`};
                          order: ${hasTable ? `1` : `0`};
                        `
                      ]}
                      src={image}
                      alt={`Tip ${title}`}
                    />
                  )}
                </>
              )}

              <div
                css={[
                  css`
                    transition: opacity 0.5s ${A.EASING_CUBIC}
                        ${active ? `0.4s` : `0.1s`},
                      transform 0.5s ${A.EASING_CUBIC}
                        ${active ? `0.4s` : `0.1s`};

                    transform: translate3d(0, ${active ? `0` : `12px`}, 0);
                    opacity: ${active ? `1` : `0`};

                    margin-top: ${!hasTable && image ? `1rem` : `2rem`};
                    margin-bottom: ${!hasTable && image ? `2rem` : `1rem`};

                    a {
                      pointer-events: ${active ? `auto` : `none`};
                      text-decoration: underline;
                    }
                  `,
                  tw`w-5/6 md:w-1/2 relative text-h4 text-koamaru text-center`
                ]}
                dangerouslySetInnerHTML={{ __html: description || `` }}
              />
            </div>
          </article>
        </Grid>
      </div>
    </div>
  );
};

export default Tip;
